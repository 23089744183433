import React, { Fragment } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { css } from "@emotion/react"
import {
  CssMobile,
  CssDesktop,
  CssTablet,
} from "../components/utils/responsive"
import {
  content_frame_desktop,
  content_frame_tablet,
  frame_common,
} from "../components/utils/css"

const FormContent = () => (
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLScFnphMoKx_oiTJZXcBT4_sdXl1f8O26rRbHpR89xWhwf05NQ/viewform?embedded=true"
    frameBorder="0"
    marginHeight="0"
    marginWidth="0"
    width="100%"
    height="100%"
    scrolling="no"
    title="contact_form"
  >
    読み込んでいます…
  </iframe>
)
const TITLE = "Contact"
class Contact extends React.Component {
  render() {
    return (
      <Fragment>
        <Layout location={this.props.location}>
          <Seo title={`${TITLE}`} slug={"/contact/"} />
          <section
            css={css`
              div {
                height: 130vh;
                padding-top: 1rem;
              }
              ${frame_common};
              ${CssDesktop(content_frame_desktop)};
              ${CssTablet(content_frame_tablet)};
              ${CssMobile(css`
                div {
                  height: 200vh;
                }
              `)};
            `}
          >
            <div>
              <FormContent />
            </div>
          </section>
        </Layout>
      </Fragment>
    )
  }
}

export default Contact
